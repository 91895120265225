.styles_react-code-input-container__tpiKG {
  width: auto !important;
}

.styles_react-code-input-container__tpiKG .styles_react-code-input__CRulA input {
  border: none;
  width: 40px !important;
  height: 40px !important;
  margin-right: 32px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 24px;
  background-color: #F6F8FB;
  border-radius: 4px;
  font-weight: bold;
  line-height: 24px;
  padding: 0;
}

.styles_react-code-input-container__tpiKG .styles_react-code-input__CRulA input[value]:not([value=""]) {
  /* border: none; */
}

.styles_react-code-input-container__tpiKG .styles_react-code-input__CRulA input:last-child {
  border-right: none;
  margin-right: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.styles_react-code-input-container__tpiKG .styles_react-code-input__CRulA input:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.styles_react-code-input-container__tpiKG .styles_react-code-input__CRulA input:focus {
  border: 1px solid #6F40EE;
  /* background-color: transparent; */
  caret-color: #6F40EE;
  line-height: 24px;
  font-size: 24px;
}

@media (max-width: 960px) {
  .styles_react-code-input-container__tpiKG {
    width: auto !important;
    margin-left: 30px;
    margin-right: 30px;
  }

  .styles_react-code-input-container__tpiKG .styles_react-code-input__CRulA input {
    border: none;
    width: 40px !important;
    height: 40px !important;
    margin-right: 12px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
    background-color: #F6F8FB;
    border-radius: 4px;
    font-weight: bold;
    line-height: 24px;
  }
}

/** 深色模式 */

.dark_code-input .styles_react-code-input__CRulA input {
  background-color: #343343;
  color: #fff;
}

.dark_code-input .styles_react-code-input__CRulA input:focus {
  caret-color: #fff;
  border-color: #fff;
}

/** 错误场景 */
.error_code-input .styles_react-code-input__CRulA input,
.error_code-input .styles_react-code-input__CRulA input:focus {
  border: 1px solid #ED220D;
  border-right: 1px solid #ED220D !important;
}

.error_code-input .styles_react-code-input__CRulA>input:focus+input {
  border-left: 1px solid #ED220D;
}