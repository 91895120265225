.text-align-right {
	text-align: right;
}
.rise-price{
  color: #1EAE45;
}
.fall-price{
	color:  #ED220D;
}
.flat-price {
	color:  #8E939F;
}
.text-align-center {
	text-align: center;
}

.copyright {
	background-color: #f6f8fb;
	text-align: center;
	font-size: 12px;
	color: #8E939F;
}

@media screen and (max-width: 960px) {
	.copyright {
		white-space: pre-line;
	}
}

@media screen and (max-width: 768px) {
	.mask-top-64,.mask-zindex-10 .ant-modal-mask  {
		top: 44px !important;
	}
}



.mask-top-64 {
    position: fixed;
    top: 64px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
}


.mask-zindex-10 .ant-modal-mask {
	z-index: 10;
	top: 64px;
}



@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.btn-primary {
		@apply items-center justify-center flex  h-12 text-center align-top text-lg  font-semibold leading-6 whitespace-nowrap text-white bg-[#6F40EE] rounded-lg  disabled:bg-[#6F40EE]/40 disabled:cursor-not-allowed  hover:enabled:bg-blend-multiply hover:enabled:bg-[linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1))]

	}
	.btn-secondary {
		@apply items-center justify-center flex  h-12 text-center align-top text-lg  font-semibold leading-6 whitespace-nowrap text-black bg-[#F6F8FB] rounded-lg  disabled:bg-[#F6F8FB]/40 disabled:cursor-not-allowed  hover:enabled:bg-blend-multiply hover:enabled:bg-[linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1))]
	}

}


/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}