.h5NavLink{
    display: none;
}
.navLink {
    margin-top: -4px;
    min-height: 500px;
}
.footerData {
    text-align: center;
}
.footerData :global .ant-layout-footer {
    font-size: 12px;
    color: #8E939F;
}
@media screen and (max-width: 960px){
    .h5NavLink{
        padding-top: 15px;
        display: block;
    }
    .navLink :global .ant-collapse .ant-collapse-header {
        font-size: 16px;
        font-weight: 700;
    }
    .navLink :global .ant-collapse .ant-collapse-content-box {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .navLink :global .ant-collapse .ant-collapse-content-box li {
        margin-bottom: 10px;
    }
    .navLink :global .ant-collapse .ant-collapse-content-box li a{
        font-size: 12px;
        color: #8E939F;
        margin-bottom: 10px;
    }
}