.kiki-slider {
  margin-top: 0;
  margin-bottom: 0;
  box-sizing: border-box;
}
.kiki-slider > .ant-slider-rail,
.kiki-slider:hover > .ant-slider-rail {
  background: #696F7F;
  opacity: 0.1;
}

.kiki-slider > .ant-slider-track {
  background: #6F40EE;
}

.kiki-slider:hover > .ant-slider-track {
  background: #6F40EE;
}

.kiki-slider > .ant-slider-step > .ant-slider-dot {
  width: 8px;
  height: 8px;
  transform: rotate(-315deg) !important;
  border: 2px solid #FFFFFF;
  border-radius: 2px;
  margin-left: -4px;
  background: #EEEEEE;
}

.kiki-slider > .ant-slider-step > .ant-slider-dot-active {
  background: #6F40EE;
}

.kiki-slider > .ant-slider-handle {
  width: 14px;
  height: 14px;
  transform: rotate(-315deg) !important;
  box-sizing: border-box;
  background: #6F40EE;
  margin-left: -7px;
  border: 2px solid #FFFFFF;
  border-radius: 3.59px;
}

.kiki-slider:hover > .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #FFFFFF;
}

.kiki-slider > .ant-slider-handle:focus {
  box-shadow: none !important;
}

.kiki-slider > .ant-slider-mark > .ant-slider-mark-text {
  display: none;
}