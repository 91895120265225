.kiki-popover.no-arrow{
  padding: 0;
}
.kiki-popover.no-arrow .ant-popover-content .ant-popover-arrow {
    display: none !important;
}
.kiki-popover .ant-popover-inner {
  border-radius: 12px;
}
.kiki-popover .ant-popover-inner-content {
  padding: 0;
  max-width: 600px;
  word-wrap: break-word
}

.kiki-popover-mode{
   max-width: 640px;
   /* z-index: 2; */
}
.kiki-popover-mode .ant-popover-inner-content{
  padding: 0;
  max-width: 600px;
  word-wrap: break-word
}
.kiki-popover-mode .kiki-popover-mode-content{
  font-weight: 400;
  font-size: 14px;
  color: #696F7F;
  margin-top: 16px;
}

.kiki-popover-mode .ant-popover-inner{
  box-shadow: 0 2px 24px 0 rgba(200,201,204,0.50);
  border-radius: 12px;
  padding: 20px;
}
.kiki-popover-mode .kiki-popover-mode-header{
  /* font-family: PingFangSC-Medium; */
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}
.kiki-popover-mode .ant-btn:nth-child(1){
  margin-right: 12px;
}
.kiki-popover-mode .ant-btn{
  /* width: 60px; */
  height: 28px;;
  border-radius: 4px;
}

.kiki-popover-mode .ant-btn >span{
  /* font-family: PingFangSC-Medium; */
  font-weight: 500;
  font-size: 14px;
}
.kiki-popover-mode .kiki-popover-mode-footer{
  margin-top: 12px;
  text-align: right;
}

.nopadding .ant-btn:nth-child(1){
  margin: 0;
}

.kiki-popover-mode-footer-three .ant-btn:nth-child(2){
  margin-right: 12px;
}
.kiki-popover-mode-footer-three .ant-btn:nth-child(3){
  background: #6F40EE;
  color: white;
}

@media (max-width: 960px) {

  .kiki-popover .ant-popover-inner {
    border-radius: 2px;
  }
  .kiki-popover-mode{
    max-width: calc(100% - 30px);
  }
}