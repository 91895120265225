
ul,li {
  padding: 0;
  margin: 0;
  list-style: none;
}
html {
  scroll-padding-top: 64px;
  /*needed to match the designs correctly on macOS
  see https://github.com/vector-im/riot-web/issues/11425
  */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}


main {
  background-color: #F6F8FB !important;
}


input::-webkit-credentials-auto-fill-button {
  display: none !important;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}
input:-internal-autofill-selected {
  box-shadow: inset 0 0 0 1000px #F6F8FB !important;
}

@media screen and (max-width: 960px) {
  html {
    scroll-padding-top: 44px;
  }
}
/* body::-webkit-scrollbar {
  display: none;
  -ms-overflow-style:none;
  scrollbar-width: none;
} */
