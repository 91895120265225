.kiki-search-Popover {
  padding: 0;
  width: 400px;
}
.kiki-search-Popover .ant-popover-arrow {
    display: none;
}
.kiki-search-Popover .ant-popover-inner-content {
  padding: 12px 0;
}
.kiki-search-Popover .menu-search {
  padding: 6px 14px 6px 14px;
}
.kiki-search-Popover .menu-search .kiki-input {
  height: 28px;
  border-radius: 14px;
  padding: 6px 12px;
  font-size: 12px;
}

.kiki-search-Popover .ant-popover-inner {
  border-radius: 4px;
  box-shadow: 0 2px 24px 0 rgba(200,201,204,0.5);
}

.kiki-search-Popover .menu {
  height: 315px;
  overflow: hidden;
}

.kiki-search-Popover .menu-wrap {
  height: 274px;
  overflow-y: scroll;
}

.kiki-search-Popover .menu-item  {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  display: flex;
  margin-left: 1px;
  padding-left: 11px;
  padding-right: 12px;
}
.kiki-search-Popover .menu-item.active {
  background-color: rgba(111, 64, 238, 0.1);
  border-radius: 4px;
  color: #6F40EE;
}
.kiki-search-Popover .menu-item .pre-icon {
  margin-right: 8px;
}
.kiki-search-Popover .menu-item .label {
  /* font-size: 14px; */
}
.kiki-search-Popover .menu-item .suffix-icon-container {
  margin-left: auto;
}
.kiki-search-Popover .menu-item .suffix-icon {
  /* margin-right: 12px; */
}
.single-select-search  .select-button {
  position: relative;
  padding: 12px;
  height: 48px;
  background-color: #F6F8FB;
  border: 1px solid #F6F8FB;
  border-radius: 4px;
}
.single-select-search  .select-button .btn-text {
  position: absolute;
  top: 12px;
  left: 12px;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
}
.single-select-search .select-button .arrow-icon { 
  position: absolute;
  top: 10px;
  right: 12px;
  height: 24px;
  line-height: 24px; 
  padding-top: 0;
}

.single-select-search .select-button.ant-btn:hover, 
.single-select-search .select-button.ant-btn:focus {
  color: rgba(0,0,0,0.80);
}
.single-select-search .select-button.ant-popover-open {
  background-color: #fff;
  border: 1px solid #6F40EE;
}
/* 定义滚动条样式*/

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.kiki-search-Popover .menu-wrap::-webkit-scrollbar
{
	width: 4px;
	height: 12px;
	border-radius: 2px;
}
/*定义滚动条轨道 内阴影+圆角*/
.kiki-search-Popover .menu-wrap::-webkit-scrollbar-track
{
	border-radius: 20px;
}
/*定义滑块 内阴影+圆角*/
.kiki-search-Popover .menu-wrap::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #6F40EE;
}

/* 选择国家特定组件样式  start */
.contry-select.single-select-search,
.ant-btn.select-button.contry-select {
  width: 128px;
}
.contry-select.single-select-search.no-code,
.ant-btn.select-button.contry-select.no-code {
  width: 76px;
}
/* 选择国家特定组件样式  end */

@media (max-width: 960px) {
  .kiki-search-Popover {
    width: 345px;
  }

  .kiki-search-Popover .ant-popover-inner-content {
    padding: 10px 0;
  }
  .kiki-search-Popover .menu-search {
    padding: 6px 10px 6px 10px;
  }
  .kiki-search-Popover .menu {
    height: 275px;
  }
  
  .kiki-search-Popover .menu-wrap {
    height: 232px;
  }
  .kiki-search-Popover .menu-item  {
    padding-right: 10px;
  }
  .single-select-search  .select-button {
    padding: 10px;
    height: 44px;
  }
  .single-select-search  .select-button .btn-text {
    top: 10px;
    left: 10px;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
  }
  .single-select-search .select-button .arrow-icon { 
    right: 10px;
  } 

  /* 选择国家特定组件样式  start */
  .contry-select.single-select-search,
  .ant-btn.select-button.contry-select {
    width: 102px;
  }
  .contry-select.single-select-search.no-code,
  .ant-btn.select-button.contry-select.no-code {
    width: 60px;
  }
  /* 选择国家特定组件样式  end */
}

@media (max-width: 370px) {
  .kiki-search-Popover {
    width: 100%;
    padding: 0 15px;
  }
}

