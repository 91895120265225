/** Select */
.kiki-select__container {
  width: fit-content;
  position: relative;
}

.kiki-select__container .kiki-select__button {
  border: 2px solid #F6F8FB;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  box-sizing: border-box;
  font-size: 14px;
  align-items: center;
  height: 36px;
  box-shadow: none;
}

.kiki-select__container .dark_kiki-select__button {
  border-color: #333444;
  background-color: #141420;
}

.kiki-select__container .kiki-select__button .label {
  font-size: 14px;
  color: #696F7F;
  margin-right: 1.5em;
}

.kiki-select__container .kiki-select__button .value {
  color: #000;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.kiki-select__container .kiki-select__button .value-space-between {
  justify-content: space-between;
  width: 100%;
}

.kiki-select__container .kiki-select__button .value-space-between .valueText {
  flex: 1;
  text-align: left;
}

.kiki-select__container .kiki-select__button .value-space-between .dark_valueText {
  color: #fff;
}

.kiki-select__container .kiki-select__button--active {
  background-color: #F6F8FB;
  /* border-radius: 8px; */
}

.kiki-select__container .dark_kiki-select__button--active {
  background-color: #141420;
}

.kiki-select__container .kiki-select__button .value .select-arrow {
  margin-left: 5px;
  transition: 0.2s;
}

.kiki-select__container .kiki-select__button .value .select-arrow-up {
  transform: rotate(180deg);
}


.kiki-select-Popover {
  padding-top: 0;
  width: 100%;
}

.kiki-select-Popover .ant-popover-inner {
  border-radius: 12px;
}

.dark_kiki-select-Popover .ant-popover-inner {
  background: #201F2D;
}

.kiki-select-Popover .ant-popover-inner-content {
  padding: 12px 0;
  width: 100%;
}


.kiki-select-Popover .ant-popover-arrow {
  display: none;
}

.kiki-select-Popover__container .search-container,
.kiki-select-Popover__container .menu-wrap .menu-item {
  padding-left: 12px;
  padding-right: 12px;
}

.kiki-select-Popover__container .select-search {
  height: 36px;
  padding: 0 8px;
}

.kiki-select-Popover__container .menu-wrap {
  font-size: 14px;
  max-height: 200px;
  overflow-y: auto;
}

.kiki-select-Popover__container .menu-wrap .menu-item {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.kiki-select-Popover__container .menu-wrap .dark_menu-item {
  position: relative;
}

.kiki-select-Popover__container .menu-wrap .dark_menu-item>* {
  z-index: 3;
}

.kiki-select-Popover__container .menu-wrap .menu-item span {
  font-family: 'DINPro-Medium';
}

.kiki-select-Popover__container .menu-wrap .menu-item .content {
  display: flex;
  align-items: center;
}

.kiki-select-Popover__container .menu-wrap .dark_menu-item .content {
  color: #8E939F;
}



/** Empty Start */

.kiki-select-empty {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  color: #696f7f;
}

/** Empty End */

.kiki-select-Popover__container .menu-wrap .menu-item--active {
  font-weight: 700;
  color: #6f40ee;
}

.kiki-select-Popover__container .kiki-select-search {
  margin-bottom: 3px;
}

.kiki-select-Popover__container .menu-wrap .menu-item:hover {
  background-color: #F6F8FB;
}

.kiki-select-Popover__container .menu-wrap .dark_menu-item:hover {
  background-color: transparent;
}

.kiki-select-Popover__container .menu-wrap .dark_menu-item:hover .content {
  color: #fff;
}

.kiki-select-Popover__container .menu-wrap .dark_menu-item:hover::after {
  content: '';
  position: absolute;
  width: calc(100% - 16px);
  border-radius: 4px;
  height: 100%;
  left: 8px;
  background-color: #353444;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.kiki-select-Popover__container .menu-wrap::-webkit-scrollbar {
  width: 4px;
  height: 12px;
  border-radius: 2px;
}

/*定义滚动条轨道 内阴影+圆角*/
.kiki-select-Popover__container .menu-wrap::-webkit-scrollbar-track {
  border-radius: 20px;
}

/*定义滑块 内阴影+圆角*/
.kiki-select-Popover__container .menu-wrap::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #6F40EE;
}

.dark_kiki-select-Popover__container .ant-empty-description {
  color: #8E939F;
}

@media (max-width: 960px) {
  .responsive-container.kiki-select__container .kiki-select__button .label {
    position: absolute;
    top: -25px;
    left: 0;
    font-size: 12px;
    line-height: 16px;

  }

  .responsive-container.kiki-select__container .kiki-select__button .value {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}