.ant-tabs-tab-btn{
font-size: 14px;
color: #000000;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-size: 14px;
  color: #000000;
}

.ant-tabs-ink-bar{
  background: #6F40EE;
}
/* tab 开始横向滚动的左右边界阴影 --- start --- */
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after, 
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after, 
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after, 
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 4%);
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  box-shadow: inset 10px 0 8px -8px rgb(0 0 0 / 4%);
}
/* tab 开始横向滚动的左右边界阴影 --- end --- */
