.kiki-drawer.ant-drawer-bottom {
}
.kiki-drawer.ant-drawer-bottom .ant-drawer-content {
    border-radius: 12px 12px 0 0;
    padding: 20px 15px;
}
.kiki-drawer.ant-drawer-bottom .ant-drawer-header {
    padding: 0;
    border-bottom: none;
}

.kiki-drawer.ant-drawer-bottom .ant-drawer-header-title {
    position: relative;
}
.kiki-drawer.ant-drawer-bottom .ant-drawer-close {
    position: absolute;
    margin-right: 0;
    right: 0;
    color: #696F7F;
}
.kiki-drawer.ant-drawer-bottom .ant-drawer-title {
    font-family: DINPro-Medium;
    font-size: 16px;
    color: #1F2126;
 }
html[lang="zh-TC"] .kiki-drawer.ant-drawer-bottom .ant-drawer-title,
html[lang="zh-CN"] .kiki-drawer.ant-drawer-bottom .ant-drawer-title
{
   font-family: PingFangSC-Medium;
   font-weight: 500;
}
