.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
}
.icon-up {
  width: 8px;
  height: 7px;
  background-size: 8px 7px;
  background-image: url(~@icon/arrowUpSolid.webp);
}
.icon-down {
  width: 8px;
  height: 7px;
  background-size: 8px 7px;
  background-image: url(~@icon/arrowDownSolid.webp);
}
.icon-up-active {
  width: 8px;
  height: 7px;
  background-size: 8px 7px;
  background-image: url(~@icon/arrowUpSolidActive.webp);
}
.icon-down-active {
  width: 8px;
  height: 7px;
  background-size: 8px 7px;
  background-image: url(~@icon/arrowDownSolidActive.webp);
}


.icon-search {
  background-image: url(~@icon/search.webp);
}
.icon-check {
  width: 12px;
  height: 10px;
  background-size: 12px 10px;
  background-image: url(~@icon/check.webp);
}

.icon-man {
  width: 15px;
  height: 11px;
  background-size: 11px;
  background-image: url(~@icon/maleIcon.webp);
}

.icon-female {
  width: 15px;
  height: 11px;
  background-size: 11px;
  background-image: url(~@icon/female.webp);
}

.icon-pointer {
  cursor: pointer;
}

.icon-edit-line {
  width: 18px;
  height: 18px;
  background-size: 18px;
  background-image: url(~@icon/edit-line.webp);
}

.icon-eyeOutlined {
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-image: url(~@icon/eyeTwoTone.webp);
}

.icon-eyeInvisibleOutlined {
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-image: url(~@icon/eyeInvisible.webp);
}

.icon-eyeOutlined-small {
  width: 16px;
  height: 16px;
  background-size: 16px;
  background-image: url(~@icon/eyeTwoTone.webp);
}

.icon-eyeInvisibleOutlined-small {
  width: 16px;
  height: 16px;
  background-size: 16px;
  background-image: url(~@icon/eyeInvisible.webp);
}


.icon-help {
  width: 12px;
  height: 12px;
  padding: 0;
  border-radius: 50%;
  background-size: 12px;
  background-image: url(~@icon/help.webp);
}
.icon-help-dark {
  width: 12px;
  height: 12px;
  padding: 0;
  border-radius: 50%;
  background-size: 12px;
  background-image: url(~@icon/questionDark.webp);
}

.icon-arrow-right {
  width: 11px;
  height: 11px;
  background-size: 11px;
  background-image: url(~@icon/arrowDown.webp);
  transform: rotate(270deg);
}

.icon-todo {
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-image: url(~@icon/todo.webp);
}

.icon-done {
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-image: url(~@icon/done.webp);
}

.icon-depositOtc {
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-image: url(~@icon/depositOtc.webp);
}
.icon-pointer {
  cursor: pointer;
}

.icon-modal-close {
  width: 22px;
  height: 22px;
  background-size: 22px;
  background-image: url(~@icon/close1.webp);
}

.icon-menu1 {
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-image: url(~@icon/menu1.webp);
}

.icon-star {
  width: 16px;
  height: 16px;
  background-size: 16px;
  background-image: url(~@icon/star.webp);
}

.icon-star-selected {
  width: 16px;
  height: 16px;
  background-size: 16px;
  background-image: url(~@icon/star_selected.webp);
}

.icon-riseAndFall {
  width: 14px;
  height: 14px;
  background-size: 14px;
  background-image: url(~@icon/riseAndFall.webp);
}

.icon-rise {
  width: 14px;
  height: 14px;
  background-size: 14px;
  background-image: url(~@icon/rise.webp);
}

.icon-fall {
  width: 14px;
  height: 14px;
  background-size: 14px;
  background-image: url(~@icon/fall.webp);
}

.icon-btc {
  width: 14px;
  height: 14px;
  background-size: 14px;
  background-image: url(~@icon/btc.webp);
}

.icon-trumpet {
  width: 18px;
  height: 18px;
  background-size: 18px;
  background-image: url(~@icon/trumpet.webp);
}

.icon-play {
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-image: url(~@icon/play.webp);
}