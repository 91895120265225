

@font-face {
    font-family: 'DINPro';
    src: url('/fonts/DINPro-Medium.woff2') format('woff2'),
        url('/fonts/DINPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINPro';
    src: url('/fonts/DINPro-Bold.woff2') format('woff2'),
        url('/fonts/DINPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINPro';
    src: url('/fonts/DINPro-Regular.woff2') format('woff2'),
        url('/fonts/DINPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins-Regular.woff2') format('woff2'),
        url('/fonts/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins-Bold.woff2') format('woff2'),
        url('/fonts/Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}




html[lang='en'] * {
   font-family: DINPro;
}

html[lang="zh-TC"] *,
html[lang="zh-CN"] * {
   font-family: PingFangSC-Regular, arial, GlowSansSC, SourceHanSansCN;
}



html[lang="zh-TC"] .PingFangSC-Semibold,
html[lang="zh-CN"] .PingFangSC-Semibold,
html[lang="zh-CN"] .Bold {
   font-family: PingFangSC-Semibold;
   font-weight: 700;
}

html[lang="zh-TC"] .PingFangSC-Medium,
html[lang="zh-CN"] .PingFangSC-Medium,
html[lang="zh-CN"] .Medium,
html[lang="zh-TC"] .Medium {
   font-family: PingFangSC-Medium;
   font-weight: 500;
}

html .DINPro-Medium,
html[lang='en'] .Medium {
   font-family: DINPro;
   font-weight: 500;
}

html .DINPro-Bold,
html[lang='en'] .Bold {
   font-family: DINPro;
   font-weight: 700;
}

.DINPro {
   font-family: DINPro !important;
   font-weight: 400;
}

.fontMedium {
   font-weight: 500;
   font-family: DINPro, PingFangSC-Medium !important;
}

.fontBold {
   font-weight: 700;
   font-family: DINPro, PingFangSC-Semibold !important;
}