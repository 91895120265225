.kiki-tooltip .ant-tooltip-content {

}
.kiki-tooltip .ant-tooltip-inner{
  color: #000;
  padding: 20px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
}
