.app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 50px;

    .app-title {
        font-size: 32px;
        font-weight: bold;
    }

    .btn-action {
        margin-top: 20px;
    }

    .address {
        margin-top: 16px;
        font-size: 18px;
        font-weight: 500;
    }
}

.connect-modal {
    .ant-modal-footer {
        display: none;
    }
    .ant-modal-title {
        font-size: 20px;
        font-weight: bold;
    }
    .wallet-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        div {
            height: 40px;
            font-size: 18px;
            width: 100%;
            line-height: 40px;
            margin: 8px 0;
            font-weight: 500;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            &:hover {
                background-color: #188fff33;
            }

        }
    }
    .particle-methods {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 12px;
        gap: 6px;
        flex-wrap: wrap;
        img {
            height: 60px;
            width: 60px;
            cursor: pointer;
        }
    }
}
