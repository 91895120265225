.countDown {
  line-height: '17px';
  display: 'inline-block';
  width: '85px';
  text-align: 'right';
  font-size: '12px';
  cursor: 'pointer';
  font-family: 'DINPro';
  color: #6F40EE;
}

.countDownIng {
  color: rgba(111, 64, 238, 0.4);
}

.darkCountDown {
  color: #fff;
}