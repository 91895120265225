.kiki-checkBox-group .ant-checkbox-checked .ant-checkbox-inner,
.kiki-checkBox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6F40EE;
  border-color: #6F40EE;
}
.kiki-checkBox-group .ant-checkbox .ant-checkbox-inner,
.kiki-checkBox-group .ant-checkbox-checked::after,
.kiki-checkBox .ant-checkbox .ant-checkbox-inner,
.kiki-checkBox .ant-checkbox-checked::after {
  border-radius: 4px;
}

.kiki-checkBox .ant-checkbox-checked::after,
.kiki-checkBox.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.kiki-checkBox .ant-checkbox:hover .ant-checkbox-inner,
.kiki-checkBox .ant-checkbox-input:focus + .ant-checkbox-inner,
.kiki-checkBox-group .ant-checkbox-checked::after,
.kiki-checkBox-group .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.kiki-checkBox-group .ant-checkbox:hover .ant-checkbox-inner,
.kiki-checkBox-group .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #6F40EE;
}

.kiki-checkBox-group .ant-checkbox+span,
.kiki-checkBox .ant-checkbox+span {
  color: #696F7F;
}
.kiki-checkBox-group .ant-checkbox-disabled+span,
.kiki-checkBox .ant-checkbox-disabled+span {
  opacity: 0.4;
}
.kiki-checkBox .ant-checkbox-disabled .ant-checkbox-inner,
.kiki-checkBox-group .ant-checkbox-disabled .ant-checkbox-inner {
  background: rgba(0,0,0,0.04);
  border: 1px solid rgba(0,0,0,0.15);
}
.kiki-checkBox-group .ant-checkbox-checked .ant-checkbox-inner::after,
.kiki-checkBox .ant-checkbox-checked .ant-checkbox-inner::after {
  border-width: 1px;
}



.kiki-checkBox-round .ant-checkbox .ant-checkbox-inner,
.kiki-checkBox-round .ant-checkbox-checked::after {
  border-radius: 50%;
}