.searchContainer {
  width: fit-content;
  display: flex;
}

.searchContainer .kiki-coinSearch-searchInput {
  height: 36px;
  min-width: 320px;
  padding: 5px 12px;
}
.searchContainer .kiki-coinSearch-searchInput .ant-input-prefix {
  margin-right: 8px;
}
.searchContainer .kiki-coinSearch-searchInput .ant-input {
  line-height: 24px;
}

.searchContainer .kiki-coinSearch-searchInput .anticon {
  font-size: 16px;
  color: #D8D8D8;
}
.searchPop {
  min-width: 320px;
  height: 380px;
  display: flex;
  /* width: 100%; */
}
.searchPopContainer {
  min-width: 320px;
  height: 380px;
  padding: 16px 0;
  /* overflow: hidden; */
}
.overflowHidden {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

}
.menuTitle {
  padding: 0 12px;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
}
.delIconContainer {
  font-size: 20px;
  color: #434B5F;
}
.delIconContainer:hover {
  color: #6F40EE;
  cursor: pointer;
}
.menuItem {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  padding: 0 12px;
  cursor: pointer;
  font-size: 14px;
}
.menuItem:hover {
  background-color: rgba(246,248,251,0.45);
}
.menuItemImgContainer {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.marginTop16 {
  margin-top: 16px;
}
.searchPopContainerEmpty {
  display: flex;
  justify-content: center;
  align-items: center;;
  min-width: 320px;
  height: 380px;
  font-size: 14px;
  color: #696F7F;
}

.statusOne{
  width: 320px;
  height: 32px;
  border-radius: 16px !important;
  padding: 4px 8px 4px 12px !important;
  transition: width 0.3s !important;
  box-sizing: border-box;
    /* position: relative; */
  /* border: none !important; */
}

.statusOne .ant-input::placeholder{
  font-size: 14px;
}


.statusTwo{
  width: 32px;
  height: 32px;
    /* position: relative; */
  padding: 0 !important;
  /* border: none !important; */
  /* border: 1px solid #F6F8FB !important; */
  justify-content: center;
  align-items: center;
  border-radius: 16px !important;
  /* margin-right: 20px; */
  padding: 0;
  transition: width 0.3s !important;
}
.statusTwo  .ant-input{
  display: none;
}


.statusTwo  .ant-input-suffix,.statusOne  .ant-input-suffix{
  position: absolute;
  right: 7px;
  top: 7px;
  margin: 0;
}

.statusTwo  .anticon:hover,.statusOne  .anticon:hover{
  color: #6F40EE !important;
}
.statusTwo  .anticon,.statusOne  .anticon{
  font-size: 16px !important;
  /* color: #696f7f; */
}

.statusTwo::placeholder{
    display: none !important;
}

 /* placeholder 样式 -- start -- */
.statusTwo > ::-webkit-input-placeholder,
.statusTwo::-webkit-input-placeholder { /* Chrome/Opera/Safari */
color: rgba(0, 0, 0,0)
}
.statusTwo > ::-moz-placeholder,
.statusTwo::-moz-placeholder { /* Firefox 19+ */
color: rgba(0, 0, 0,0)
}
.statusTwo > :-ms-input-placeholder,
.statusTwo:-ms-input-placeholder { /* IE 10+ */
color: rgba(0, 0, 0,0)
}
.statusTwo > :-moz-placeholder,
.statusTwo:-moz-placeholder { /* Firefox 18- */
color: rgba(0, 0, 0,0)
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.searchPopContainer .overflowHidden::-webkit-scrollbar
{
	width: 0;
	height: 12px;
	border-radius: 2px;
}
/*定义滚动条轨道 内阴影+圆角*/
.searchPopContainer .overflowHidden::-webkit-scrollbar-track
{
	border-radius: 20px;
}
/*定义滑块 内阴影+圆角*/
.searchPopContainer .overflowHidden::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #6F40EE;
}

.kiki-navabar-search-contain{
  position: relative;
    margin-right: 20px;
}

.kiki-navabar-search-icon{
  position: absolute;
  right: 0;
  top: 17px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* top */
}

@media (max-width: 960px) {
  /* .searchPop {
      width: 100%;
  } */
  .searchPopContainer {
    width: 91.5vw;
    background: white;
  }
  .searchContainer  .ant-tabs-extra-content{
    position: absolute;
    top: 0;
    width: 100%;
  }
  .searchContainer  .ant-popover-inner-content{
    max-width: 100%;
  }
  .searchContainer{
    width: 100%;
  }
}