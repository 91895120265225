.primaryBtn {
  background-color: #6F40EE;
  border-radius: 4px;
  height: 48px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  border: none;
}
.primaryBtn[disabled], .primaryBtn[disabled]:hover, .primaryBtn[disabled]:focus, .primaryBtn[disabled]:active{
  background-color: rgba(111, 64, 238, 0.4);
  color: rgba(255,255,255, 1);
}
.disabledBtn {
  background-color: #D9DBDF !important;
  color: #fff !important;
}