.relaxUs{
    margin: 35px 24px 20px 24px;
    font-size: 16px;
}
.relaxDataImage {
    margin-bottom: 20px;
    display: flex;
}
.relaxImage{
    margin-left: 14px;
}
@media screen and (max-width: 960px){
    .relaxData {
        width: 100%;
        padding-left: 16px;
    }
    .relaxUs{
        margin: 20px 0;
    }
    .relaxImage{
        margin-left: 0;
        margin-right: 24px;
    }
}
