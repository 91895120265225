/* 布局 */
.kiki-table.ant-table-wrapper {
    display: flex;
    flex-direction: column;
}

.kiki-table .ant-spin-nested-loading {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.kiki-table .ant-spin-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.kiki-table .ant-table-content {
    margin-bottom: 32px;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.kiki-table .ant-table-content::-webkit-scrollbar {
    /* width: 4px; */
    height: 4px;
    border-radius: 2px;
}

/*定义滚动条轨道 内阴影+圆角*/
.kiki-table .ant-table-content::-webkit-scrollbar-track {
    border-radius: 20px;
}

/*定义滑块 内阴影+圆角*/
.kiki-table .ant-table-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(142, 147, 159, 0.3);
}

.kiki-table .ant-table-pagination {
    margin: auto auto 32px auto;
}

/* head */
.kiki-table .ant-table-thead>tr>th {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding: 14px 8px;
    height: 0px;
    line-height: 24px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: normal;
}

.kiki-table .ant-table-thead>tr>th:last-child {
    text-align: right;
}

.kiki-table .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}

/* body */
.kiki-table .ant-table-tbody>tr>td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    height: 63px;
    line-height: 33px;
    padding: 15px 8px;
    font-size: 14px;
    color: #000000;
    vertical-align: middle;
}

.kiki-table .ant-table-tbody>tr>td:last-child {
    text-align: right;
}

.kiki-table .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: #F6F8FB;
}

/* 多选框样式 */
.kiki-table .ant-table-tbody>tr>td .ant-checkbox-inner {
    border-radius: 50%;
    /* background-color:rgba(111, 64, 238, 1) ; */
    /* ant-checkbox-checked */
}

.kiki-table .ant-table-tbody>tr>td .ant-checkbox-inner::after {
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    border-color: #ccc;
    opacity: 1;
}

.kiki-table .ant-table-tbody>tr>td .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: rgba(111, 64, 238, 1);
}

.kiki-table .ant-table-tbody>tr>td .ant-checkbox-checked .ant-checkbox-inner {
    border-radius: 50%;
    border-color: rgba(111, 64, 238, 1);
    background-color: rgba(111, 64, 238, 1);
}

.kiki-table .ant-table-tbody>tr>td .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff;
}

.kiki-table .ant-table-tbody>tr>td .ant-checkbox-checked::after {
    border-radius: 50%;
    border-color: rgba(111, 64, 238, 1);
    /* background-color:rgba(111, 64, 238, 1) ; */
}

/* 拖动样式 */
.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.row-dragging td>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.row-dragging>td:last-child {
    margin-right: 8px;
}

.row-dragging>td:first-child {
    margin-left: 8px;
}

.row-dragging td .ant-checkbox-inner {
    border-radius: 50%;
    /* background-color:rgba(111, 64, 238, 1) ; */
    /* ant-checkbox-checked */
}

.row-dragging td .ant-checkbox-inner::after {
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    border-color: #ccc;
    opacity: 1;
}

.row-dragging td .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: rgba(111, 64, 238, 1);
}

.row-dragging td .ant-checkbox-checked .ant-checkbox-inner {
    border-radius: 50%;
    border-color: rgba(111, 64, 238, 1);
    background-color: rgba(111, 64, 238, 1);
}

.row-dragging td .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff;
}

.row-dragging td .ant-checkbox-checked::after {
    border-radius: 50%;
    border-color: rgba(111, 64, 238, 1);
    /* background-color:rgba(111, 64, 238, 1) ; */
}

/* 空表格状态 */
.kiki-table .ant-table-cell {
    padding: 0;
    border-bottom: none;
}

.kiki-table .ant-table-cell .empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.kiki-table .ant-table-cell .empty-container .empty-img {
    margin-top: 48px;
    margin-bottom: 24px;
}

.kiki-table .ant-table-cell .empty-container .empty-text {
    font-size: 14px;
    color: #696F7F;
}

/* 分页 */
.kiki-table .ant-pagination-jump-prev,
.kiki-table .ant-pagination-jump-next,
.kiki-table .ant-pagination-item:not(.ant-pagination-item-active) {
    display: none;
}

.kiki-table .ant-pagination-item-active {
    height: 32px;
    width: 32px;
    background-color: #F6F8FB;
    border: 2px solid #F6F8FB;
    border-radius: 8px;
    margin-right: 12px;
}

.kiki-table .ant-pagination-item-active:hover {
    background-color: #F6F8FB;
    border: 2px solid #F6F8FB;
    border-radius: 8px;
}

.kiki-table .ant-pagination-item-active a {
    cursor: default;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
}

.kiki-table .prev-icon-container,
.kiki-table .next-icon-container {
    height: 32px;
    width: 32px;
    line-height: 28px;
    background-color: #FFF;
    border: 2px solid #F6F8FB;
    border-radius: 8px;
    color: rgba(67, 75, 96, 1);
}

.kiki-table .prev-icon-container:hover,
.kiki-table .next-icon-container:hover {
    border: 2px solid rgba(111, 64, 238, 0.1);
    color: rgba(111, 64, 238, 1)
}

.kiki-table .ant-pagination-disabled .prev-icon-container,
.kiki-table .ant-pagination-disabled .next-icon-container {
    color: rgba(67, 75, 96, 0.4);
}

.kiki-table .ant-pagination-disabled .prev-icon-container:hover,
.kiki-table .ant-pagination-disabled .next-icon-container:hover {
    border: 2px solid #F6F8FB;
    color: rgba(67, 75, 96, 0.4);
}

.kiki-table .ant-pagination-prev {
    margin-right: 12px;
}

/* 排序 */
.kiki-table .ant-table-column-sorters {
    justify-content: flex-start;
}

.kiki-table .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #fff;
}

.kiki-table .ant-table-column-sorters .ant-table-column-title {
    flex: none;
}

.kiki-table .ant-table-column-sorter-inner {
    margin-top: 6px;
}

.kiki-table .ant-table-column-sorter-up,
.kiki-table .ant-table-column-sorter-down {
    font-size: 8px;
}

.kiki-table .ant-table-column-sorter-up.active,
.kiki-table .ant-table-column-sorter-down.active {
    color: #6F40EE;
}

.kiki-table .ant-table-column-sort {
    background-color: #fff;
}

/* 操作按钮 */
.kiki-table-action-btn {
    color: #6F40EE;
    font-size: 14px;
}

.kiki-table-action-btn:hover {
    color: #6F40EE;
}

.kiki-table-action-btn:not(:last-child) {
    margin-right: 20px;
}

.kiki-table-action-btn-button {
    background-color: rgba(111, 64, 238, 0.1);
    color: rgba(111, 64, 238, 1);
    border: none;
    box-shadow: none;
    height: 32px;
}

.kiki-table-action-btn-button:hover,
.kiki-table-action-btn-button:focus,
.kiki-table-action-btn-button:active {
    background-color: rgba(111, 64, 238, 0.1);
    color: rgba(111, 64, 238, 1);
    height: 32px;
}

/* 二级表格 */
.kiki-table .ant-table-expanded-row .ant-table {
    background: #F7F7F7;
    margin: 0 !important;
}

.kiki-table .ant-table-expanded-row .ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table {
    margin: 0;
}

.kiki-table .ant-table-expanded-row .ant-table-content {
    margin: 0;
    padding: 0;
}

.kiki-table .ant-table-expanded-row .ant-table-cell {
    padding: 0;
}

.kiki-table .ant-table-expanded-row .ant-table-tbody>tr>td {
    padding: 0 0 0 50px;
    font-size: 14px;
    color: #434B5F;
    letter-spacing: 0;
}

.kiki-table .ant-table-expanded-row .ant-table-thead>tr>th {
    background: #F7F7F7;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0;
    padding-left: 50px;
    padding-top: 15px;
    padding-bottom: 10px;
}

@media (max-width: 960px) {
    .kiki-table .ant-table-tbody>tr>td {
        padding-left: 0;
        padding-right: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
}