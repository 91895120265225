.image_borderRadius_3px {
  border-radius: 3px;
}
.image_borderRadius_4px {
  border-radius: 4px;
}

.image_borderRadius_5px {
  border-radius: 5px;
}
.image_borderRadius_6px {
  border-radius: 6px;
}
.image_borderRadius_10px {
  border-radius: 10px;
}
.image_borderRadius_12px {
  border-radius: 12px;
}
.image_borderRadius_8px {
  border-radius: 8px;
}
.image_borderRadius_18px {
  border-radius: 18px;
}
.image_borderRadius_20px {
  border-radius: 20px;
}
.image_borderRadius_24 {
  border-radius: 24px;
}
.image_borderRadius_24px {
  border-radius: 24px;
}
.image_borderRadius_32px,
.image_borderRadius_32 {
  border-radius: 32px;
}

.image_borderRadius_34 {
  border-radius: 34px;
}
.image_borderRadius_34px {
  border-radius: 34px;
}
.image_borderRadius_40px {
  border-radius: 40px;
}
.image_borderRadius_48px {
  border-radius: 48px;
}
.image_borderRadius_48 {
  border-radius: 48px;
}
.image_borderRadius_60px {
  border-radius: 60px;
}
.image_borderRadius_72px {
  border-radius: 72px;
}
.image_borderRadius_80px {
  border-radius: 80px;
}
