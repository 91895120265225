
/* 清除 antd 导航样式 --- start --- */
.ant-menu .ant-menu-item-active,
.ant-menu > .ant-menu-item-selected a,
.ant-menu-item a:hover,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item a:hover,
.ant-menu .ant-menu-item-selected .ant-menu-item-icon {
  color: #7A34F7;
}
.ant-menu-item:active,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
}
.ant-menu .ant-menu-item-active::after,
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
  border-bottom: none;
}
.ant-menu-vertical {
  border-right: none;
}
/* 清除 antd 导航样式 --- end --- */

/* 切换语言select --- start --- */
.language-select .ant-select:not(.ant-select-disabled) .ant-select-selector,
.language-select .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #F6F8FB;
  background-color: #F6F8FB;
  border-radius: 16px;
  color: #949494;
  font-size: 14px;
}
.language-select .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.language-select .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #F6F8FB;
  box-shadow: none;
}
.language-select .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding-right: 8px;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(111,64,238,0.1);
}
/* 切换语言select --- end --- */

/* 导航抽屉 */
.navDrawer .ant-drawer-close {
  position: absolute;
  right: 0;
  top: 20px;
}
/* footer 折叠标题 */
.ant-collapse-header {
  font-size: 14px;
  color: #1F2126;
  font-family: Helvetica;
}

.ant-drawer-body {
  padding: 0;
}

/* upload组件通过class自定义大小 */
.image-avatar-uploader > .ant-upload.ant-upload-select  {
  width: 100%;
  /* height: 40px; */
}
[ant-click-animating-without-extra-node='true']::after{display:none;}

/* form 表单 error 样式  start */
.ant-form-item-explain-error {
  font-size: 12px;
  line-height: 17px;
  margin-top: 2px;
  color: #ED220D;
}
.register-password-form .ant-form-item {
  margin-bottom: 0;
}
.register-password-form .ant-form-item-with-help .ant-form-item-explain {
  height: 0;
  min-height: 0;
  transition: none;
}
.register-password-form .ant-form-item-has-success {
  margin-bottom: 0;
}
.bottom-class  .ant-form-item{
  margin-bottom: 12px
}
.bottom-class  .ant-form-item-control-input-content .ant-form-item{
  margin-bottom: 0
}
/* form 表单 error 样式  end */


/* modal 样式  start */
.ant-modal-content {
  border-radius: 12px;
}
.ant-modal-body {
  padding: 48px 30px 60px;
}
.ant-modal-header {
  border-radius: 12px 12px 0 0;
  padding: 0 30px;
  height: 60px;
  display: flex;
  align-items: center;

}
.ant-modal-title {
  color: #1F2126;
  font-size: 20px;
  font-weight: bold;
}
.ant-modal-close-x {
  width: 28px;
  height: 28px;
  line-height: normal;
  margin: 14px 14px 0 0;
}

.ant-btn{
  box-shadow: none;
}
.ant-layout-header{
  background: white;
}

@media (max-width: 960px) {
  .ant-layout-header {
    height: 44px 
  }

  .ant-modal-header {
    height: 44px;
    padding: 0;
    /* background: #F6F5FA; */
  }
  .ant-modal-title {
    font-size: 18px;
    text-align: center;
    width: 100%;
  }
  .ant-modal-title-close-icon {
    width: 24px !important;
    height: 24px !important;
  }
  .ant-modal-close-x {
    width: 24px;
    height: 24px;
    line-height: normal;
    margin: 7px 7px 0 0;
  }

  /* 解决移动端弹框页面抖动问题 */
  .ant-scrolling-effect{
    width: 100vw !important;
  }




}
/* modal 样式  end */

.ant-layout{
  background-color: #F6F8FB;
}

/* table 开始横向滚动的左右边界阴影 --- start --- */
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 4%);
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgb(0 0 0 / 4%);
}
/* table 开始横向滚动的左右边界阴影 --- end --- */
