.ant-back-top {
  width: 52px;
  height: 52px;
  background-color: #fff;
  border-radius: 52px;
  box-shadow: 0 2px 24px 0 rgba(200, 201, 204, 0.5);
  line-height: 52px;
  text-align: center;
  font-size: 24px;
  color: #000;
  right: calc(50% - 669px);
  bottom: 40px;
}

.ant-back-top:hover {
  cursor: pointer;
  color: #6F40EE;
}

@media (max-width: 1330px) {
  .ant-back-top {
    right: 5px;
  }
}

@media (max-width: 960px) {
  .ant-back-top {
    right: 20px;
    bottom: 26px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}