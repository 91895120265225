.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.8);
}

.ant-image-preview-operations {
  background-color: transparent;
}

.ant-image-preview-img {
  max-width: 971px;
  max-height: 780px;
}

@media screen and (max-width: 960px) {
  .ant-image-preview-img {
    max-width: calc(100% - 30px);
    max-height: auto;
  }
}

.ant-image-preview-operations-progress {
  display: none;
}

.ant-image-preview-wrap .anticon:hover {
  color: #6F40EE;
}

.ant-image-preview-switch-left-disabled>.anticon:hover,
.ant-image-preview-switch-right-disabled>.anticon:hover {
  color: inherit;
}

.ant-image-mask {
  opacity: 0;
  display: none;
}

.ant-image {
  cursor: pointer;
}