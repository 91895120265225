#tradingViewIframe {
	width: 100%;
	height: 480px;
}

@media screen and (max-width: 960px){
	#tradingViewIframe {
		width: 100%;
		height: 480px;
	}

}
@media only screen and (min-width:961px) and (max-width:1200px){



}
@media only screen and (min-width:1201px) and (max-width:1439px){

}
