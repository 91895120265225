.mr20 {
  margin-right: 20px;
}
.mb32 {
  margin-bottom: 32px;
}
.mb18 {
  margin-bottom: 18px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb48 {
  margin-bottom: 48px;
}

.pl24{
  padding-left: 24px;
}

.pr24{
  padding-right: 24px;
}
.pl32{
  padding-left: 32px;
}

.pr32{
  padding-right: 32px;
}