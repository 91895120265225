    .kiki-toptabs{
    display: flex;
    border-radius: 0 0 12px 12px;
    }

    .kiki-toptabs   .ant-tabs-nav-list{
        padding-left: 7px;
        background: #FFFFFF;
        padding-bottom: 6px;
        border-radius: 0 0 6px 6px;
        padding-top: 6px;
    }
    .kiki-toptabs   .ant-tabs-nav{
        margin: 0;
    }
    .kiki-toptabs   .ant-tabs-tabpane{
        /* font-family: DINPro-Medium; */
        font-size: 24px;
        color: #000000;
        letter-spacing: 0;
        background: white;
        /* padding: 12px 20px; */
        /* font-weight: 500; */
    }
    .kiki-toptabs   .ant-tabs-tab-btn{
        border-radius: 36px;
        color: #696F7F;
        font-size: 14px;
        letter-spacing: 0;
        text-align: center;
        line-height: 20px;
    }
    html[lang='en'] .kiki-toptabs   .ant-tabs-tab-btn
    {
        font-family: DINPro-Medium;
    }

    html[lang="zh-TC"] .kiki-toptabs   .ant-tabs-tab-btn,
    html[lang="zh-CN"] .kiki-toptabs   .ant-tabs-tab-btn
    {
        font-family: PingFangSC-Medium;
    }

    .kiki-toptabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab{
        margin: 0 6px;
        /* padding: 8px 12px 7px 12px; */
        padding: 6px 14px;
        min-width: 84px;
        height: 36px;
        align-items: center;
        justify-content: center;
        border-radius: 18px;
        background-color: white;
        color: #696F7F;
        background: #FFFFFF;
        border: 2px solid #F6F8FB;
    }
    .kiki-toptabs   .ant-tabs-ink-bar{
        display: none;
        }

    .kiki-toptabs   .ant-tabs-tab-active .ant-tabs-tab-btn{
        background-color: #F6F8FB;
        font-size: 14px;
        color: #000000;
    }
    .kiki-toptabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab.ant-tabs-tab-active {
        background-color: #F6F8FB;
    }
    .kiki-toptabs   .ant-tabs-nav-wrap{
        min-width: 100%;
        background: white;
        border-radius: 0 0 6px 6px;
    }
    .kiki-toptabs   .ant-list-item-meta-title{
        min-width: 100%;
        background: white;
        margin-bottom: 4px;
    }

    .kiki-toptabs   .ant-list-item{
       padding: 23px 0 21px 0;
    }

    .kiki-toptabs   .site-layout-background{
    background: white !important;
   }
  
