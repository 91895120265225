.ant-btn.kiki-button.ant-btn-primary {
  background-color: #6F40EE;
  border-radius: 4px;
  height: 48px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  /* font-weight: bold; */
  border: none;
}

.ant-btn.kiki-button {
  transition-duration: 50ms;
  box-shadow: none;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active,
.ant-btn-ghost:hover,
.ant-btn-ghost:focus
.ant-btn-ghost:active{
  background: linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.1));
  background-blend-mode: multiply;
  color: rgba(255,255,255, 1);
}

.ant-btn.kiki-button.ant-btn-primary[disabled],
.ant-btn.kiki-button.ant-btn-primary[disabled]:hover,
.ant-btn.kiki-button.ant-btn-primary[disabled]:focus,
.ant-btn.kiki-button.ant-btn-primary[disabled]:active {
  background-color: rgba(111, 64, 238, 0.4);
  color: rgba(255,255,255, 0.4);
}

.ant-btn.kiki-button .ant-btn-loading-icon {
  font-size: 24px;
}

@media (max-width: 960px) {
  .ant-btn.kiki-button.ant-btn-primary {
    height: 40px;
    font-size: 14px;
    line-height: 20px;
  }
  
  .ant-btn.kiki-button .ant-btn-loading-icon {
    font-size: 20px;
  }
}


.kiki-button.ant-btn-ghost {
  background-color: #F6F8FB;
  /* background-color: rgba(0, 0, 0, 0.06); */
  color: #000;
  border-width: 0;
}
.kiki-button.ant-btn-ghost[disabled] {
  color: rgba(0,0,0,0.4);
}