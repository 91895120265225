.ant-message .ant-message-notice:first-child{
    margin-top: 88px;
}
.ant-message .ant-message-notice:not(first-child){
    margin-top: 16px;
}
.ant-message-notice {
    padding: 0px;
    text-align:  end;
    margin-right: 40px;
} 
.ant-message-notice-content {
    display: inline-block;
    padding: 14px 16px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 24px rgba(200,201,204,0.5);
    pointer-events: all;
    font-size: 14px;
    line-height: 20px;
    
}

.ant-message-notice.ant-message-move-up-leave.ant-message-move-up-leave-active {
    animation-name: MessageMoveOut;
    animation-duration: 0.3s;
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 0px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-message-custom-content.ant-message-success {
    color: #1EAE45;
}

.ant-message-custom-content.ant-message-error {
    color: #ED220D;
}

.ant-message-custom-content.ant-message-warning {
    color: #FFC241;
}
.anticon.anticon-check-circle {
    width: 16px;
    height: 16px;
    margin-right: 16px;
    color: #1EAE45;
}

.anticon.anticon-exclamation-circle {
    width: 16px;
    height: 16px;
    margin-right: 16px;
    color: #FFC241;
}

.anticon.anticon-close-circle {
    width: 16px;
    height: 16px;
    margin-right: 16px;
    color: #ED220D;
}
.ant-message-custom-content.ant-message-loading .anticon-loading {
    width: 16px;
    height: 16px;
    margin-right: 16px;
    color: #6F40EE;
}