/* 深色主题 */
.dark-navLink {
  background-color: #090636;
}
.dark-navHeader {
  color: #FFF;
}
.dark-relaxUs {
  color: #FFF;
}
.dark-footerData :global .ant-layout-footer {
  background-color: #090636;
}

.dark-selectData :global .ant-btn {
  background: #211E49 !important;
  border: 1px solid #211E49!important;
  color: #FFF;
}

.dark-selectData :global .ant-btn .valueText {
  color: #fff;
}

.dark-selectData :global .ant-popover-inner {
  background: #211E49 !important;
}
.dark-selectData :global .kiki-select-Popover__container .menu-wrap .menu-item:hover::after{
  background-color: #282059;
}
.dark-selectData :global .kiki-select-Popover__container .menu-wrap .menu-item:hover span{
  color: #fff;
}

/* h5页面代码 */
@media screen and (max-width: 960px){
  .dark-navLink :global .ant-collapse .ant-collapse-header {
    color: #fff !important;
  }
}

/* 灰色主题 */
.grey-navLink {
  background-color: #F6F8FB;
}
.grey-navHeader {
  color: #696F7F;
}
.grey-relaxUs {
  color: #696F7F;
}
.grey-footerData :global .ant-layout-footer {
  background-color: #F6F8FB;
}

.grey-selectData :global .ant-btn {
  background: #FFF !important;
  border: 1px solid #FFF!important;
  color: #696F7F;
}

.grey-selectData :global .ant-btn .valueText {
  color: #696F7F;
}

.grey-selectData :global .kiki-select-Popover__container .menu-wrap .menu-item:hover::after{
  background-color: #F6F8FB;
}
.grey-selectData :global .kiki-select-Popover__container .menu-wrap .menu-item:hover span{
  color: #6F40EE;
}

/* h5页面代码 */
@media screen and (max-width: 960px){
  .grey-navLink :global .ant-collapse .ant-collapse-header {
    color: #696F7F;
  }
}