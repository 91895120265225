.navLink {
  margin-top: -4px;
  min-height: 500px;
}
.navLinkMenu {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
}
.navLinkMenuLeft {
  display: flex;
  flex: 1;
  margin: 35px 0;
}
.navCont {
  width: 180px;
}
.navCont:not(:first-child) {
  margin-top: 37px;
}
.navHeader {
  margin-bottom: 20px;
  font-size: 16px;
}
.navLinkMenuRight {
  display: flex;
  width: 196px;
  justify-content: right;
}
.footerData {
  text-align: center;
}
.footerData :global .ant-layout-footer {
  font-size: 12px;
  color: #8E939F;
}
@media screen and (min-width: 960px) and (max-width: 1231px){
  .navLinkMenu {
    margin-right: 15px;
    margin-left: 15px;
    width: calc(100% - 30px);
}
}
@media screen and (max-width: 960px){
  .webNavLink{
      display: none;
  }
}