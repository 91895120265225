 /* placeholder 样式 -- start -- */
 .kiki-input> ::-webkit-input-placeholder,
 .kiki-input::-webkit-input-placeholder {
   /* Chrome/Opera/Safari */
   color: rgba(0, 0, 0, 0.4)
 }

 .kiki-input> ::-moz-placeholder,
 .kiki-input::-moz-placeholder {
   /* Firefox 19+ */
   color: rgba(0, 0, 0, 0.4)
 }

 .kiki-input> :-ms-input-placeholder,
 .kiki-input:-ms-input-placeholder {
   /* IE 10+ */
   color: rgba(0, 0, 0, 0.4)
 }

 .kiki-input> :-moz-placeholder,
 .kiki-input:-moz-placeholder {
   /* Firefox 18- */
   color: rgba(0, 0, 0, 0.4)
 }

 .dark_kiki-input.ant-input::placeholder,
 .dark_kiki-input .ant-input::placeholder {
   color: #797A90;
 }

 /* placeholder 样式 -- end -- */

 .kiki-input.ant-input,
 .kiki-input.ant-input-password,
 .kiki-input.ant-input-affix-wrapper {
   /* caret-color: 光标样式 Chrome和Firefox 兼容性好 Safari和IE不兼容，后期考虑其他方案 */
   caret-color: #6F40EE;
   background-color: #F6F8FB;
   border: 1px solid #F6F8FB;
   border-radius: 8px;
   padding: 11px;
   line-height: 24px;
   font-size: 14px;
 }

 /** 深色模式  */
 .dark_kiki-input.ant-input,
 .dark_kiki-input.ant-input-password,
 .dark_kiki-input.ant-input-affix-wrapper {
   caret-color: #fff;
   background-color: transparent;
   border-color: #333444;
   color: #fff;
 }

 .kiki-input .ant-input {
   box-shadow: none;
   background-color: #F6F8FB;
   font-family: 'DINPro';
 }

 .dark_kiki-input.ant-input,
 .dark_kiki-input .ant-input {
   background-color: transparent;
   color: #fff;
   caret-color: #fff;
   border-color: #333444;
 }

 .kiki-input.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
 .kiki-input.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
 .kiki-input.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
 .kiki-input.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
   background: rgba(240, 91, 76, 0.03);
   box-shadow: none;
 }

 .kiki-input.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
   border-color: rgba(237, 34, 13, 0.80);
 }

 .kiki-input.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper>[type="password"] {
   box-shadow: none !important;
   /* box-shadow: inset 0 0 0 1000px rgba(240,91,76,0.03) !important; */
 }

 .kiki-input.ant-input-affix-wrapper-status-error .ant-input,
 .kiki-input.ant-input-affix-wrapper-status-error .ant-input:focus {
   background: rgba(240, 91, 76, 0.00);
   box-shadow: none !important;
   box-shadow: inset 0 0 0 1000px #fffafa !important;
 }

 /** 深色模式 */
 .dark_kiki-input.ant-input-affix-wrapper-status-error .ant-input,
 .dark_kiki-input.ant-input-affix-wrapper-status-error .ant-input:focus {
   background: rgba(240, 91, 76, 0.00);
   box-shadow: none !important;
 }

 .kiki-input.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
   box-shadow: none;
   border: 1px solid #F6F8FB;
 }

 /** 深色模式 */
 .dark_kiki-input.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
   box-shadow: none;
   border-color: #333444;
 }

 .kiki-input.ant-input-affix-wrapper-focused:not(.ant-input-affix-wrapper-disabled):hover {
   border: 1px solid #6F40EE;
 }

 /** 深色模式 */
.dark_kiki-input.ant-input-affix-wrapper-focused:not(.ant-input-affix-wrapper-disabled):hover {
   border-color: #333444;
 }

 .kiki-input.ant-input:focus,
 .kiki-input.ant-input-focused,
 .kiki-input.ant-input-affix-wrapper:focus,
 .kiki-input.ant-input-affix-wrapper-focused {
   box-shadow: none;
   border: 1px solid #6F40EE;
   background-color: #fff;
 }

 /** 深色模式 */
 .dark_kiki-input.ant-input:focus,
 .dark_kiki-input.ant-input-focused,
 .dark_kiki-input.ant-input-affix-wrapper:focus,
 .dark_kiki-input.ant-input-affix-wrapper-focused {
   box-shadow: none;
   border-color: #333444;
   background-color: transparent;
 }

 .dark_kiki-input.ant-input:focus {
   background-color: transparent;
   border-color: #333444;
 }

 .kiki-input.ant-input-affix-wrapper-focused .ant-input,
 .kiki-input.ant-input-affix-wrapper:focus .ant-input {
   background-color: #fff;
 }

 /** 深色模式 */
 .dark_kiki-input.ant-input-affix-wrapper-focused .ant-input,
 .dark_kiki-input.ant-input-affix-wrapper:focus .ant-input {
   background-color: transparent;
 }

 .kiki-input.ant-input-affix-wrapper-focused.ant-input-affix-wrapper-status-error .ant-input {
   background: rgba(240, 91, 76, 0.00);
   box-shadow: none;
 }

 .kiki-input .ant-input-password-icon {
   width: 24px;
   height: 24px;
   margin-left: 12px;
 }

 .kiki-input .ant-input-clear-icon {
   margin: 0
 }

 .forget-pwd-input {
   position: relative;
   z-index: 1;
 }

 .forget-text {
   z-index: 99;
   position: absolute;
   font-size: 12px;
   top: 14px;
   right: 42px;
   color: #6F40EE;
 }

 .forget-text a {
   cursor: pointer;
   color: #6F40EE;
 }

 .suffix {
   color: #6F40EE;
   font-size: 12px;
   cursor: pointer;
 }

 .disabled_suffix {
   color: rgba(0, 0, 0, 0.4);
 }

 .dark_suffix {
   color: #fff;
 }

 /* 表头上方的搜索框  start */
 .kiki-input.ant-input-affix-wrapper.input-search {
   height: 36px;
   padding: 6px 8px;
 }

 /* 表头上方的搜索框  end */

 @media (max-width: 960px) {

   .kiki-input.ant-input,
   .kiki-input .ant-input,
   .kiki-input.ant-input-password,
   .kiki-input.ant-input-affix-wrapper {
     padding: 9px;
     line-height: 24px;
     font-size: 12px;
   }
 }

 .renderInputClear {
   font-size: 24px;
 }