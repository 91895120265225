.kiki-startUse-main{
  width: 380px;
  height: 48px;
  margin-top: 35px;
  display: flex;
}
.kiki-startUse-main .kiki-input.ant-input{
  width: 380px;
  border-radius: 8px 0 0 8px;
  background: white;
  border: none
}
.kiki-startUse-main input:hover,.kiki-startUse-main .kiki-input.ant-input:focus{
  border: none;
}

.kiki-startUse-main .ant-btn{
  height: 100%;
  width: 120px;
  border-radius: 0 8px 8px 0 !important;
  background: #6F40EE;
  border: none;
  font-size: 14px;
  color: #FFFFFF;
}

@media screen and (max-width: 960px){
  .kiki-startUse-main{
    display: block;
    width: auto;
    height: auto;
    margin-top: 24px;
  }
  .kiki-startUse-main .kiki-input.ant-input{
    width: 100%;
    border-radius: 8px;
    background: #F6F8FB;
    border: none;
    padding: 14px 12px;
    height: 48px;
  }
  .kiki-startUse-main .kiki-input.ant-input::placeholder{
    color: #B8BDCB;
    font-size: 14px;
  }
  .kiki-startUse-main .ant-btn{
  height: 48px;
  width: 100%;
  border-radius: 8px !important;
  background: #6F40EE;
  border: none;
  font-size: 14px;
  color: #FFFFFF;
  margin-top: 24px;
  font-size: 14px;
  padding: 0;
 }
 html[lang="zh-TC"] .kiki-startUse-main .ant-btn,
 html[lang="zh-CN"] .kiki-startUse-main .ant-btn
  {
    font-family: PingFangSC-Medium;
    font-weight: 500;
  }
  html[lang='en'] .kiki-startUse-main .ant-btn {
   font-family: DINPro-Medium;
}
}